@import "core";

$horizontalPadding: 8px;

.result__section {
  margin: 8px;
  padding: 0;
  border-radius: 6px;
  background-color: tint(black, 97%);
  overflow: hidden;
}

.result__title {
  font-weight: 700;
  padding: 8px $horizontalPadding;
}

.result__items {
  margin: 0;
}

.result__action {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  padding: 0 $horizontalPadding;

  // States
  &:hover {
    background-color: tint(black, 90%);
  }
}

.action__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: tint(black, 70%);
  color: white;
  border-radius: 4px;

  // States
  &.primary {
    background-color: $primary;
  }
  &.secondary {
    background-color: $secondary;
  }
  &.info {
    background-color: $info;
  }
  &.success {
    background-color: $success;
  }
  &.warning {
    background-color: $warning;
  }
  &.danger {
    background-color: $danger;
  }
}

.action__label {
  padding: 0 8px;
}
