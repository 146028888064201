/**
 * Functions
 */
@function tint($color, $amount) {
  @return mix(rgb(255, 255, 255), $color, $amount);
}

/**
 * Mixins
 */
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
 * Variables
 */
$success: rgba(108, 161, 36, 1);
$danger: rgba(217, 24, 40, 1);
$warning: rgba(231, 126, 35, 1);
$primary: rgba(0, 122, 204, 1);
$info: rgba(255, 192, 16, 1);
$secondary: rgba(142, 68, 173, 1);

$animationEase: cubic-bezier(0.4, 0, 0.2, 1);
