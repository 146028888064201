@import "core";

.layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.header {
  flex: none;
  display: flex;
  align-items: center;
}

.header__searchBar {
  flex: 1;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidebar {
  flex: none;
  width: 240px;
  display: flex;
  flex-direction: column;
}

.logo {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  // Children
  img {
    width: 36px;
  }
}

.logo__payoff {
  color: white;
  margin-left: 12px;
  font: {
    size: 28px;
    weight: 300;
  }
}

.menu {
  flex: 1;
  overflow: auto;
}

.routes {
  position: relative;
  flex: 1;
  background-color: white;
}
