@import "core";

.link {
  color: rgba(white, 0.7);
  border-left: 4px solid transparent;

  // States
  &:hover {
    background-color: rgba(white, 0.05);
  }
  &.active {
    border-left-color: rgb(0, 127, 239);
    color: white;
    background-color: transparent;
  }
}

.label {
  flex: 1;
  margin-left: 12px;
}

.icon {
  font-size: 16px;
}
