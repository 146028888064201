@import "core";

.selectItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body {
  padding: 16px;
}

.body__text {
  margin-bottom: 16px;
  line-height: 1.5;
}
