@import "core";

.avatar {
  flex: none;
  margin: 0 16px;
  display: flex;
  align-items: center;
  cursor: default;
}

.trigger {
  flex: none;
  margin-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.name {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(white, 0.7);
  font-weight: 600;
}

.realEstateAgency {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(white, 0.5);
  font: {
    weight: 400;
    size: 11px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.context {
  background-color: white;
  border: 1px solid tint(black, 90%);
  overflow: hidden;
  min-width: 200px;
}

.actionItem {
  cursor: pointer;

  // States
  &:not(:last-child) {
    border-bottom: 1px solid tint(black, 90%);
  }
  &:hover {
    background-color: tint(black, 95%);
  }
}

.actionItemIcon {
  font-size: 16px;
}

.actionsLabel {
  margin-right: 8px;
}

.actionsTrigger {
  color: white;
  font-size: 13px;
}
