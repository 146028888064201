@import "core";

.tab {
  flex: 1;
  max-width: 240px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: default;
  padding-left: 10px;
  position: relative;
  border-radius: 5px 5px 0 0;

  // States
  &:hover {
    background-color: tint(black, 15%);
  }
  &.active {
    background-color: tint(black, 25%);
    z-index: 1;

    // Children
    &:not(:first-child)::before,
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: transparent;
      box-shadow: 0 0 0 20px tint(black, 25%);
      border-radius: 50%;
      bottom: 0;
    }
    &:not(:first-child)::before {
      left: -20px;
      clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
    }
    &::after {
      right: -20px;
      clip-path: polygon(0 50%, 50% 50%, 50% 100%, 0 100%);
    }
    .typeIcon {
      color: $primary;
    }
  }
  &.hasError {
    // Children
    .typeIcon {
      color: $danger;
    }
  }

  // Children
  &:not(.active):not(:last-child)::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: tint(black, 30%);
    right: -1px;
    top: 6px;
  }
}

.hasChanges {
  flex: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.hasChanges {
  background-color: $warning;
}

.label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  font-weight: 500;
}

.typeIcon {
  flex: none;
  margin-right: 8px;
  color: tint(black, 40%);
  font-size: 15px;
}

.close {
  flex: none;
  display: flex;
  justify-content: center;
  width: 32px;
  cursor: pointer;
  font-size: 15px;
  color: tint(black, 70%);

  // States
  &:hover {
    color: white;
  }
}
