@import "core";

.tabs {
  flex: none;
  display: flex;
  align-items: center;
  color: white;
  border-bottom: 5px solid tint(black, 25%);
}

.create {
  flex: none;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 24px;
  position: relative;
  background-color: $success;
  border-radius: 5px 5px 0 0;
  margin-right: 3px;

  // Children
  .typeIcon {
    flex: none;
    margin-right: 8px;
    color: white;
    font-size: 15px;
  }
  .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
    font-weight: 500;
  }
}

.context {
  background-color: white;
  border: 1px solid tint(black, 90%);
  overflow: hidden;
  min-width: 200px;
}

.createItem {
  cursor: pointer;

  // States
  &:not(:last-child) {
    border-bottom: 1px solid tint(black, 90%);
  }
  &:hover {
    background-color: tint(black, 95%);
  }
}

.createItemIcon {
  font-size: 16px;
}
