@import "core";

$inputHeight: 36px;
$verticalOffset: 8px;
$buttonWidth: 256px;
$horizontalPadding: 8px;

.globalSearch {
  padding: $verticalOffset 0;
}

.globalSearch__wrapper {
  position: relative;
  z-index: 10;
  display: flex;

  // Children
  input {
    flex: 1;
    height: $inputHeight;
    padding: 0 $horizontalPadding 0 0;
    border: none;
    outline: none;
    margin: 0;
  }
  button {
    display: flex;
    align-items: center;
    flex: none;
    width: $buttonWidth;
    border: none;
    border-left: 1px solid tint(black, 90%);
    outline: none;
    border-radius: 0 5px 5px 0;
    padding: 0 $horizontalPadding;
    margin: 0;
    cursor: pointer;
    background-color: white;

    // Children
    span {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $horizontalPadding;
    }
  }
}

.search {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px 0 0 5px;
  color: black;
  cursor: text;
  width: 40px;
}

.status {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: tint(black, 70%);
  cursor: pointer;
  width: 40px;
}

.dropdown__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.5);
  z-index: 5;
}

.dropdown {
  position: absolute;
  max-height: calc(100vh - 54px);
  background-color: white;
  border-radius: 5px;
  z-index: 5;
  top: $inputHeight + $verticalOffset + 2px;
  display: flex;
  box-shadow: 0 1px 2px rgba(black, 0.15);
  align-items: stretch;
}

.dropdown__results {
  flex: 1;
  overflow: auto;
}

.dropdown__selection {
  flex: none;
  width: $buttonWidth;
  padding: $horizontalPadding;
  border-left: 1px solid tint(black, 90%);

  // Children
  label {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 24px;
    cursor: pointer;
    position: relative;

    // States
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  input[type="checkbox"] {
    @include visually-hidden;

    // Siblings
    &:checked + .checkbox__label:before {
      background-color: $primary;
      border-color: darken($primary, 5%);
    }
  }
}

.checkbox__label {
  padding-left: 24px;

  // Children
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    border: 1px solid tint(black, 85%);
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }
  &:after {
    content: "";
    left: 6px;
    top: 6px;
    position: absolute;
    width: 5px;
    height: 9px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
}

.noTypesSelected,
.noQuery {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: tint(black, 70%);
  font: {
    size: 16px;
    weight: 500;
  }
}

.noTypesSelected {
  color: $warning;
}

.noQuery {
  color: tint(black, 70%);
}

.searchInArchive {
  margin-top: 16px;

  // Children
  label input[type="checkbox"]:checked + .checkbox__label:before {
    background-color: $secondary;
    border-color: darken($secondary, 5%);
  }
}
