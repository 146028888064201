@import "core";

$horizontalPadding: 8px;

.result__item {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 $horizontalPadding;
  cursor: pointer;

  // States
  &:hover {
    background-color: tint(black, 90%);
  }
}

.pill__wrapper {
  flex: none;

  // Siblings
  & + .pill__wrapper {
    margin-left: 5px;
  }
  & + .label {
    margin-left: 10px;
  }
}

.pill {
  background-color: tint(black, 90%);
  color: tint(black, 30%);
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}
