@import "core";

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100000;
}

.visual {
  position: relative;
  color: rgba(white, 0.1);
  margin-right: 48px;
}

.content {
  color: white;
  max-width: 300px;

  // Children
  h1 {
    font: {
      size: 96px;
      weight: 700;
    }
    margin: 0 0 24px;
  }
  h2 {
    font: {
      size: 24px;
      weight: 300;
    }
    line-height: 1.2;
    margin: 0;

    // States
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.starFighter {
  position: absolute;
  top: -10px;
  left: -10px;
  transform: rotate(-20deg);
}

.contentNavigation {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $primary;
  font-size: 16px;
  cursor: pointer;

  // States
  &:hover {
    // Children
    .link {
      text-decoration: underline;
    }
  }
}

.navStarFighter {
  color: white;
  transform: rotate(-45deg);
  margin-right: 10px;
}
