@import "core";

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.spacer {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.logo {
  flex: none;
  color: white;
  display: flex;
  justify-content: center;

  // Children
  img {
    display: block;
    height: 128px;
    margin: 24px 0;
  }
}

.icon {
  color: tint(black, 40%);
  font-size: 32px;
}
