@import "core";

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100000;
}

.content {
  color: white;
  max-width: 400px;

  // Children
  h1 {
    font: {
      size: 96px;
      weight: 700;
    }
    margin: 0 0 24px;
  }
  h2 {
    font: {
      size: 24px;
      weight: 300;
    }
    line-height: 1.2;
    margin: 0;

    // States
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.icon {
  margin-right: 10px;
  font-size: 16px;
  color: tint(black, 50%);
}
