@import "core";

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  shape-rendering: geometricPrecision;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-tap-highlight-color: inherit;
  text-rendering: inherit;
  shape-rendering: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: tint(black, 10%);
}

:root,
button,
input,
select,
textarea {
  font: {
    size: 13px;
    family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }
}

a {
  color: $primary;
  text-decoration: none;

  // States
  &:hover {
    text-decoration: underline;
  }
}

.asLink {
  color: $primary;
  text-decoration: none;
  cursor: pointer;

  // States
  &:hover {
    text-decoration: underline;
  }
}
