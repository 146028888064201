@import "core";

$horizontalPadding: 8px;

.result__item {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 $horizontalPadding;
  cursor: pointer;

  // States
  &:hover {
    background-color: tint(black, 90%);
  }
}

.pill {
  flex: none;

  // Siblings
  & + .pill {
    margin-left: 5px;
  }
  & + .label {
    margin-left: 10px;
  }
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.pill__pubref {
  color: $warning;
  background-color: tint($warning, 80%);
}

.pill__rentOrSale {
  color: $primary;
  background-color: tint($primary, 80%);
}

.pill__type {
  color: $secondary;
  background-color: tint($secondary, 80%);
}

.openButton {
  margin-left: auto !important;
  height: 24px !important;
  padding: 0 $horizontalPadding !important;

  cursor: pointer;
}
